.ontimemarker {
  height: 23px;
  width: 40px;
  border-radius: 5px;
  background-image: url(https://assetsstatic.s3.ap-south-1.amazonaws.com/truck_on_time.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.delaymarker {
  height: 23px;
  width: 40px;
  border-radius: 5px;
  background-image: url(https://assetsstatic.s3.ap-south-1.amazonaws.com/truck_delayed.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
/* body {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
}
.globalFont {
  font-family: 'Montserrat', sans-serif;
} */